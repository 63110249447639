<template>
  <div class="container">
    <div class="titleContainer">
        <a href="https://www.uglyavatar.fun/"><h1>Ugly Avatar Generator</h1></a>
        <h2>Ugly Avatar Generator will generate an amusingly crudely-drawn "ugly"portrait for you to use online.</h2>
    </div>
    <FaceGenerator />
  </div>
</template>

<script>
import FaceGenerator from './views/FaceGenerator.vue'

export default {
  components: { FaceGenerator },
  name: 'App'
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  margin: 20px;
  gap: 5px;
}

.titleContainer a {
  text-decoration: none;
  color: black;
}


</style>
