import { createApp } from 'vue'
import App from './App.vue'
// import VueGtag from 'vue-gtag';



// Vue.use(VueGtag, {
//   config: {
//     id: 'G-2CZ56DVFKX'
//   }
// })

const app = createApp(App);


app.mount('#app');
